import React from 'react'

const Racipes = () => {
    return (
        <>
            <div class="recipes">
                <div class="image"></div>
                <div class="text">
                    <h2>Tasteful</h2>
                    <h3>Recipes</h3>
                </div>
            </div>
        </>
            )
}

export default Racipes