import React from 'react'

const FixedImages = () => {
    return (
        <>
            <div class="fixed-image">
                <div class="text">
                    <h2>The Perfect</h2>
                    <h3>Blend</h3>
                </div>
            </div>
        </>
    )
}

export default FixedImages